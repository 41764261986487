import Vue from "vue";
import VueRouter from "vue-router";
const authorize = () =>
  import(/* webpackChunkName: "authorize" */ "../views/authorize");
const turntable = () =>
  import(/* webpackChunkName: "turntable" */ "../views/turntable/turntable");
const redEnvelopes = () =>
  import(
    /* webpackChunkName: "redEnvelopes" */ "../views/redEnvelopes/redEnvelopes"
  );
const codeState = () =>
  import(/* webpackChunkName: "codeState" */ "../views/codeState/codeState");
const qCodeState = () =>
  import(/* webpackChunkName: "qCodeState" */ "../views/codeState/qCodeState");
import { trackingRouterGuards } from "../utils/tracking.js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/:qrCode/:projectCode",
    name: "authorize",
    component: authorize,
  },
  {
    path: "/turntable",
    name: "turntable",
    component: turntable,
  },
  {
    path: "/redEnvelopes",
    name: "redEnvelopes",
    component: redEnvelopes,
  },
  {
    path: "/codeState",
    name: "codeState",
    component: codeState,
  },
  {
    path: "/qCodeState",
    name: "qCodeState",
    component: qCodeState,
  },
];

const router = new VueRouter({
  mode: "history",
  // base: "/web",
  // mode: "hash",
  base: process.env.VUE_APP_Router_API,
  routes,
});
router.beforeEach((to, from, next) => {
  // 页面进入前收集
  trackingRouterGuards("before", to, from);
  next();
});
export default router;
