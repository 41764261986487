// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import request from "./utils/request";
import "amfe-flexible";
import "./styles/base.css";
import scroll from "vue-seamless-scroll";
import { Toast, Area, Popup, Loading } from "vant";
Vue.use(Popup);
Vue.use(Area);
import qs from "qs";
import { nextRequest } from "./utils/tracking";
Vue.prototype.$nextRequest = nextRequest;
Vue.use(Toast);
Vue.use(scroll);
Vue.config.productionTip = false;
Vue.prototype.$baseUrl = process.env.baseUrl;
Vue.prototype.$qs = qs;
Vue.prototype.req = request;
Vue.use(Loading);
/* eslint-disable no-new */
// new Vue({
//   el: "#app",
//   router,
//   components: { App },
//   template: "<App/>",
// });
import * as fundebug from "fundebug-javascript";
import FundebugVue from "fundebug-vue";
fundebug.init({
  apikey: "80b38cf2e731101e4f72288769dbd2224d11a0ed2367653de2d67d165b145302",
  releasestage: process.env.VUE_APP_edition,
});
console.log("版本", process.env.VUE_APP_edition);
new FundebugVue(fundebug).installVueErrorHandler(Vue);
new Vue({
  el: "#app",
  // store,
  router,
  render: (h) => h(App),
}).$mount("#app");
